import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import { htmlDecode } from '@vue-storefront/core/filters'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'

export default {
  components: {
    Breadcrumbs
  },
  serverPrefetch () {
    return this.fetchPost()
  },
  data () {
    return {
      loading: false,
      relatedProducts: []
    }
  },
  computed: {
    ...mapGetters({
      post: 'wordpress/currentPost',
      categoriesPreview: 'wordpress/categoriesPreview'
    }),
    relatedPosts () {
      return null
    }
  },
  filters: {
    prettyDate: function (date) {
      let newDate = new Date(date.replace(/-/g, '/'))
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  mounted () {
    this.fetchPost()
  },
  watch: {
    '$route' (to, from) {
      this.fetchPost()
    }
  },
  metaInfo () {
    let title
    const meta = []
    if (this.post && this.post.title) {
      title = this.post.title
      meta.push({
        vmid: 'og:title',
        name: 'og:title',
        content: this.post.title
      })
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        content: htmlDecode(this.post.meta_description)
      })
      meta.push({
        vmid: 'description',
        name: 'description',
        content: htmlDecode(this.post.meta_description)
      })
    }
    return {
      title,
      meta
    }
  },
  methods: {
    refreshList () {
      if (!this.post || !this.post.related_products || !this.post.related_products.length) {
        this.relatedProducts = []
        return
      }
      let sku = this.post.related_products
      let key = 'sku'
      let relatedProductsQuery = prepareRelatedQuery(key, sku)
      this.$store.dispatch('product/list', {
        query: relatedProductsQuery,
        size: 20,
        prefetchGroupProducts: false,
        updateState: false
      }).then((response) => {
        this.relatedProducts = response.items
          ? response.items
          : []
      })
    },
    fetchPost () {
      this.loading = true
      this.$store.dispatch('wordpress/loadCategoriesPreview')
      return this.$store.dispatch('wordpress/loadPost', { slug: this.$route.params.slug })
        .then(() => this.refreshList())
        .finally(() => { this.loading = false })
    },
    getPostImage (post) {
      if (post.featured_image) {
        return {
          'backgroundImage': `url(${post.featured_image})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    sanitizeHtml: sanitizeHtml
  }
}
